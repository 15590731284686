import "./style.scss";

import { ToggleChangeEventDetail } from "@ionic/core";
import { IonCard, IonItem, IonLabel, IonToggle } from "@ionic/react";
import { useSession } from "@src/app/store/helperHooks";
import { updateRecentColleaguesList } from "@src/app/store/recentColleagues/actions";
import { updateAgent } from "@src/app/store/session";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants/userEvents";
import { Fragment } from "react";
import { useDispatch } from "react-redux";

import { updateProfile } from "./api";

export function WorkWithFriends() {
  const dispatch = useDispatch();

  const { showErrorToast } = useToast();
  const { agent } = useSession();
  if (!agent) {
    return <Fragment />;
  }

  const hideAsColleague = agent.hideAsColleagueOnShifts ?? false;

  const handleToggleChange = async (event: CustomEvent<ToggleChangeEventDetail>) => {
    const { checked } = event.detail;
    const result = await updateProfile({ hideAsColleagueOnShifts: checked });
    if (result) {
      dispatch(updateAgent({ ...agent, hideAsColleagueOnShifts: checked }));
      if (checked) {
        dispatch(updateRecentColleaguesList([], false));
      }
      logEvent(USER_EVENTS.TOGGLE_HIDE_AS_COLLEAGUE_ON_SHIFTS, {
        hcpId: agent.userId,
        state: checked ? "enabled" : "disabled",
      });
    } else {
      showErrorToast("There was an error while updating your profile");
    }
  };

  return (
    <IonCard>
      <IonItem class="ion-margin-bottom" lines="none">
        <IonLabel>Hide me as a colleague on shifts</IonLabel>
        <IonToggle color="primary" checked={hideAsColleague} onIonChange={handleToggleChange} />
      </IonItem>
    </IonCard>
  );
}
