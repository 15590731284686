import "./style.scss";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonLabel,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { addDataToIdentity } from "@src/lib/analytics";
import { ActionType, reloadAgentProfile } from "@store/session";
import { LocalStorage } from "@store/session";
import { validateAgentOTP } from "@store/session/api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { requestOTP } from "./api";
import { updateProfile } from "./api";
import { PROFILE_UPDATE_LOCAL_STORAGE_ITEMS } from "./constants";
import { USER_EVENTS } from "../../../constants";
import { formatNumberToPhoneNumber } from "../../../hooks/useTelephoneNumber";
import { OtpInputs } from "../../components/auth/otpInputs";
import { logout } from "../../store/session";

const CODE_LENGTH = 6;
interface ProfileUpdateStepProps {
  stepFinished: () => void;
}

export function UpdatePhone(props: ProfileUpdateStepProps) {
  const { stepFinished } = props;
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtp] = useState("");
  const agent = useDefinedAgent();
  const dispatch = useDispatch();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    const newPhone = localStorage.getItem(LocalStorage.NEW_PHONE) as string;
    requestOTP(newPhone);
    setPhone(newPhone);
  }, []);

  const onClickVerifyConfirmation = (newCode) => {
    setOtp(newCode);
    setShowConfirmationModal(true);
  };

  const verify = async () => {
    const otpCode = otp + "";
    if (isVerifying) {
      return;
    }
    if (otpCode.trim().length !== CODE_LENGTH) {
      return;
    }
    if (isButtonDisabled) {
      setButtonDisabled(false);
    }
    try {
      setIsVerifying(true);
      const { error } = await validateAgentOTP(phone, otpCode);
      if (error) {
        showErrorToast("Invalid OTP");
        setIsVerifying(false);
        return;
      }

      addDataToIdentity(agent.userId, {
        email: agent.email,
        phone,
        type: "AGENT",
      });
      await updateProfile({ phone });
      localStorage.setItem(
        PROFILE_UPDATE_LOCAL_STORAGE_ITEMS.UPDATE_PROFILE_PHONE_NUMBER_CHANGED,
        "true"
      );
      setIsVerifying(false);
      dispatch(reloadAgentProfile(false));
      logEvent(USER_EVENTS.PROFILE_CHANGE_COMPLETED, {
        type: "PHONE",
      });
      stepFinished();
      dispatch(logout());
    } catch (error) {
      setIsVerifying(false);
      showErrorToast("Unexpected error occurred while verifying OTP");
    } finally {
      dispatch({
        type: ActionType.SET_IS_VERIFYING_DONE,
      });
    }
  };

  const resendOTP = async () => {
    const { error } = await requestOTP(phone);
    if (!error) {
      showSuccessToast("OTP re-sent successfully.");
    }
  };

  return (
    <IonContent>
      <IonAlert
        mode="ios"
        isOpen={showConfirmationModal}
        backdropDismiss={false}
        header="You need to login again"
        message="To validate your new phone, you will need to logout and login again. This step is necessary for us to proper configure your account."
        buttons={[
          {
            text: "Cancel",
            handler: () => setShowConfirmationModal(false),
          },
          {
            text: "Confirm",
            handler: verify,
          },
        ]}
      />
      <div className="content-layout signup-content adjust-form-height verifyotp-content">
        <div className="form-container">
          <IonRow>
            <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
              <div className="form-heading">
                <h4>Enter your verification code</h4>
                <p>We&#39;ve just sent a verification code to {formatNumberToPhoneNumber(phone)}</p>
              </div>
              <div>
                <IonLabel className="form-label hcp-self-label">Verification Code</IonLabel>
                <OtpInputs
                  verify={onClickVerifyConfirmation}
                  code={code}
                  setCode={setCode}
                  noBorder
                />
              </div>
              <a className="resend-otp-link" onClick={resendOTP} role={"button"} tabIndex={0}>
                Didn&#39;t get a code? Resend it now.
              </a>
            </IonCol>
          </IonRow>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            onClick={onClickVerifyConfirmation}
            disabled={isVerifying || isButtonDisabled}
            id="verify-button"
          >
            Continue
            {isVerifying && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
}
