import { Keyboard } from "@capacitor/keyboard";
import { isPlatform } from "@ionic/core";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useSession } from "@src/app/store/helperHooks";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { addDataToIdentity } from "@src/lib/analytics";
import { getFirebaseSingleton } from "@src/lib/firebase/src";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import validator from "validator";

import { updateProfile } from "./api";
import { PROFILE_UPDATE_LOCAL_STORAGE_ITEMS } from "./constants";
import { USER_EVENTS } from "../../../constants";
import { checkIfEmailExists } from "../../onboarding/components/api";
import { logout, reloadAgentProfile } from "../../store/session";

const INVALID_EMAIL_ENTERED = "Please enter a valid email address";
interface ProfileUpdateStepProps {
  stepFinished: () => void;
}

export function UpdateEmail(props: ProfileUpdateStepProps) {
  const { stepFinished } = props;
  const { showErrorToast } = useToast();
  const { agent } = useSession();
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  const captchaRef = useRef<HTMLDivElement | null>(null);
  const [doesEmailAlreadyExist, setDoesEmailAlreadyExist] = useState(false);

  useEffect(() => {
    if (agent?.email) {
      setEmail(agent.email);
    }
  }, [agent]);

  function initiateRecapcha() {
    getFirebaseSingleton().initRecaptchaVerifier("recaptcha-container", captchaRef.current);
  }
  function showErrorAndInitiateRecapcha(errorMessage: string) {
    showErrorToast(errorMessage);
    initiateRecapcha();
  }

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event && event.preventDefault();
    if (updatingData) {
      return;
    }
    if (isPlatform("capacitor")) {
      Keyboard.hide();
    }
    try {
      setUpdatingData(true);
      const isValidEmail = validator.isEmail(email);
      if (!isValidEmail) {
        setUpdatingData(false);
        showErrorAndInitiateRecapcha(INVALID_EMAIL_ENTERED);
        return;
      }
      /**
       * An endpoint that provides anyone a way to check for existing emails is a
       * potential security risk. A hacker can use it to do an enumeration attack.
       */
      const { exists } = await checkIfEmailExists(email);
      if (!email) {
        setUpdatingData(false);
        showErrorAndInitiateRecapcha("Please enter your email address");
        return;
      }
      if (exists) {
        setUpdatingData(false);
        initiateRecapcha();
        setDoesEmailAlreadyExist(true);
        return;
      }

      setUpdatingData(false);
      setShowConfirmationModal(true);

      logEvent(USER_EVENTS.PROFILE_CHANGE_COMPLETED, {
        type: "EMAIL",
      });
    } catch (error) {
      setUpdatingData(false);
      showErrorAndInitiateRecapcha("Error occurred while updating email address.");
    } finally {
      setUpdatingData(false);
    }
  };

  const onProcessed = async () => {
    try {
      setUpdatingData(true);
      addDataToIdentity(agent?.userId, {
        email: email,
        phone: agent?.phone,
        type: "AGENT",
      });
      await updateProfile({ email });
      localStorage.setItem(PROFILE_UPDATE_LOCAL_STORAGE_ITEMS.UPDATE_PROFILE_EMAIL_CHANGED, "true");
      dispatch(reloadAgentProfile(false));
      setUpdatingData(false);
      stepFinished();
      dispatch(logout());
    } catch (error) {
      setUpdatingData(false);
      showErrorAndInitiateRecapcha("Error occurred while checking email address.");
    }
  };

  const closeModal = () => {
    setShowConfirmationModal(false);
  };

  const onEmailChange = (event: Event) => {
    setEmail(event.target?.["value"]);
  };

  return (
    <IonContent>
      <IonAlert
        mode="ios"
        isOpen={doesEmailAlreadyExist}
        backdropDismiss={false}
        message="The email you tried to submit is already in use by another account. Please make sure you are submitting an email you own and that you typed it correctly."
        header="This email is already in use"
        buttons={[
          {
            text: "Try Again",
            handler: () => {
              setDoesEmailAlreadyExist(false);
            },
          },
        ]}
      />
      <IonAlert
        mode="ios"
        isOpen={showConfirmationModal}
        backdropDismiss={false}
        header="You need to login again"
        message="To validate your new email, you will need to logout and login again. This step is necessary for us to proper configure your account."
        buttons={[
          {
            text: "Cancel",
            handler: closeModal,
          },
          {
            text: "Confirm",
            handler: onProcessed,
          },
        ]}
      />

      <div className="signup-content content-layout adjust-form-height">
        <div className="form-container">
          <form onSubmit={onNext}>
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>What&#39;s your new email?</h4>
                </div>

                <div>
                  <IonLabel className="form-label">Email Address</IonLabel>
                  <IonItem lines="none" className="form-item-wrapper">
                    <IonInput
                      name="email"
                      className="form-input"
                      type="email"
                      placeholder="Email address"
                      value={email}
                      onIonChange={onEmailChange}
                      disabled={updatingData}
                      autofocus
                      required
                    />
                  </IonItem>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            disabled={updatingData || agent?.email === email || !email}
            onClick={onNext}
          >
            Continue
            {updatingData && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
          </IonButton>
          <div ref={captchaRef}></div>
        </div>
      </div>
    </IonContent>
  );
}
